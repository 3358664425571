import React, {useEffect, useState} from "react";
import AppHeader from "../../components/header";
import Footer from "../../components/footer";
import "./style.scss";
import {useDispatch, useSelector} from "react-redux";
import {changeAppLocale, getLoginConfig} from "../../redux/slices/loginconfigSlice";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import AppConfig from "../../helpers/config";
import {setActivityIndicator} from "../../redux/slices/appconfigSlice";
// import {ServiceGetStaticPage} from "../../services/AppService";
import axios from "axios";

const PrivacyPolicy = () => {
    const url = window.location.href;
    const {t} = useTranslation();
    const objUrl = new URL(url);
    const searchParams = new URLSearchParams(objUrl.search);
    const urlLocale = searchParams.get("locale");
    const fromExternal = searchParams.get("external");
    const systemLanguage = navigator?.language;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loginConfig = useSelector(getLoginConfig);
    const [justCame, setJustCame] = useState(true);
    // const [isDutch, setIsDutch] = useState(false);
    const [htmlContent, setHtmlContent] = useState('');

    const scrollToTop = () => {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    };

    useEffect(() => {
        const changeLocale = (isDutch) => {
            dispatch(changeAppLocale({
                id: isDutch ? 0 : 1,
                country: isDutch ? "Nederlands" : "Engels",
                code: isDutch ? "nl_NL" : "en_US",
                name: isDutch ? "Dutch" : "English",
                short: isDutch ? "Nl" : "En",
            }));
        };

        const localeIncludesDutch = fromExternal === 'true' ? systemLanguage?.includes('nl') : urlLocale?.includes('nl');
        changeLocale(localeIncludesDutch);
    }, []);

    useEffect(() => {
        scrollToTop();
        const language = AppConfig.appLanguages.find(f => f.code === loginConfig?.locale?.code);
        if (!justCame) {
            navigate(`/privacy-policy/query?locale=${loginConfig?.locale?.code}&external=${false}`, {replace: true});
            dispatch(changeAppLocale(language));
        } else {
            setJustCame(false);
        }
        getStaticContent(language);
    }, [loginConfig?.locale]);


    const getStaticContent = async (language) => {
        try {
            dispatch(setActivityIndicator(true));
            const value = await localStorage.getItem("auth_token");
            const headers = {
                Authorization: `Bearer ${value}`,
                "Content-Type": "application/json",
                'X-Locale': language?.short?.toLowerCase()
            };
            const content = await axios.get(`${AppConfig.baseCMSApiURL}/pages?type=privacy_policy`, {headers});
            setHtmlContent(content?.data?.data[0]);
            dispatch(setActivityIndicator(false));
        } catch (e) {
            console.log({e}, 'static page error');
            dispatch(setActivityIndicator(false));
        }
    }

    return (
        <div className="static-page privacy-policy">
            <div className="main-wrapper">
                <div className="container">
                    <AppHeader/>
                    <main className="body-wrapper">
                        <div className="left-action-back-btn">
                            <a onClick={() => navigate(-1)}>{t("Buttons.Back")}</a>
                        </div>
                        <h2 className="text-center">{htmlContent?.title}</h2>
                        <div
                            dangerouslySetInnerHTML={{__html: htmlContent?.content}}
                        />
                    </main>
                </div>
                <Footer/>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
